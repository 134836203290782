@import "https://use.typekit.net/sod7mae.css";
*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-family: quicksand, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  color: inherit;
  border-top-width: 1px;
  height: 0;
}

abbr:where([title]) {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-feature-settings: inherit;
  font-variation-settings: inherit;
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

:is(::-webkit-inner-spin-button, ::-webkit-outer-spin-button) {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

dialog {
  padding: 0;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

.container {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 1rem;
  padding-right: 1rem;
}

@media (min-width: 640px) {
  .container {
    max-width: 640px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}

@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
  }
}

@media (min-width: 1280px) {
  .container {
    max-width: 1280px;
  }
}

@media (min-width: 1536px) {
  .container {
    max-width: 1536px;
  }
}

.visible {
  visibility: visible;
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.-top-10 {
  top: -2.5rem;
}

.bottom-8 {
  bottom: 2rem;
}

.left-0 {
  left: 0;
}

.left-auto {
  left: auto;
}

.right-0 {
  right: 0;
}

.right-8 {
  right: 2rem;
}

.top-0 {
  top: 0;
}

.z-10 {
  z-index: 10;
}

.z-40 {
  z-index: 40;
}

.z-\[-1\] {
  z-index: -1;
}

.z-\[999\] {
  z-index: 999;
}

.-mx-3 {
  margin-left: -.75rem;
  margin-right: -.75rem;
}

.-mx-4 {
  margin-left: -1rem;
  margin-right: -1rem;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.my-3 {
  margin-top: .75rem;
  margin-bottom: .75rem;
}

.mb-10 {
  margin-bottom: 2.5rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mb-6 {
  margin-bottom: 1.5rem;
}

.mb-8 {
  margin-bottom: 2rem;
}

.mt-10 {
  margin-top: 2.5rem;
}

.mt-12 {
  margin-top: 3rem;
}

.mt-6 {
  margin-top: 1.5rem;
}

.mt-\[6px\] {
  margin-top: 6px;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.flex {
  display: flex;
}

.inline-flex {
  display: inline-flex;
}

.hidden {
  display: none;
}

.h-10 {
  height: 2.5rem;
}

.h-3 {
  height: .75rem;
}

.h-full {
  height: 100%;
}

.w-1\/3 {
  width: 33.3333%;
}

.w-10 {
  width: 2.5rem;
}

.w-2\/3 {
  width: 66.6667%;
}

.w-3 {
  width: .75rem;
}

.w-full {
  width: 100%;
}

.w-max {
  width: max-content;
}

.max-w-\[1244px\] {
  max-width: 1244px;
}

.max-w-\[780px\] {
  max-width: 780px;
}

.max-w-full {
  max-width: 100%;
}

.rotate-180 {
  --tw-rotate: 180deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.rotate-45 {
  --tw-rotate: 45deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.resize {
  resize: both;
}

.flex-col {
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.items-start {
  align-items: flex-start;
}

.items-center {
  align-items: center;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.gap-4 {
  gap: 1rem;
}

.self-center {
  align-self: center;
}

.overflow-hidden {
  overflow: hidden;
}

.scroll-smooth {
  scroll-behavior: smooth;
}

.rounded {
  border-radius: .25rem;
}

.rounded-full {
  border-radius: 9999px;
}

.rounded-md {
  border-radius: .375rem;
}

.rounded-xl {
  border-radius: .75rem;
}

.rounded-t-xl {
  border-top-left-radius: .75rem;
  border-top-right-radius: .75rem;
}

.rounded-tr-xl {
  border-top-right-radius: .75rem;
}

.border {
  border-width: 1px;
}

.border-2 {
  border-width: 2px;
}

.border-l {
  border-left-width: 1px;
}

.border-t {
  border-top-width: 1px;
}

.border-white {
  --tw-border-opacity: 1;
  border-color: rgb(255 255 255 / var(--tw-border-opacity));
}

.border-opacity-40 {
  --tw-border-opacity: .4;
}

.bg-\[\#323032\] {
  --tw-bg-opacity: 1;
  background-color: rgb(50 48 50 / var(--tw-bg-opacity));
}

.bg-\[\#F6F6F6\] {
  --tw-bg-opacity: 1;
  background-color: rgb(246 246 246 / var(--tw-bg-opacity));
}

.bg-lightgreen {
  --tw-bg-opacity: 1;
  background-color: rgb(192 216 211 / var(--tw-bg-opacity));
}

.bg-primary {
  --tw-bg-opacity: 1;
  background-color: rgb(2 100 80 / var(--tw-bg-opacity));
}

.bg-transparent {
  background-color: #0000;
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.bg-leafs {
  background-image: url("hero-leaf-left.0ca2d710.svg"), url("hero-leaf-right.a334e6b0.svg");
}

.bg-suggestionleaf {
  background-image: url("suggestion-leaf-right.01a40ada.svg");
}

.bg-\[size\:20\%\] {
  background-size: 20%;
}

.bg-\[position\:bottom_left\,bottom_right\] {
  background-position: 0 100%, 100% 100%;
}

.bg-right-bottom {
  background-position: 100% 100%;
}

.bg-no-repeat {
  background-repeat: no-repeat;
}

.p-6 {
  padding: 1.5rem;
}

.px-2 {
  padding-left: .5rem;
  padding-right: .5rem;
}

.px-3 {
  padding-left: .75rem;
  padding-right: .75rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.px-7 {
  padding-left: 1.75rem;
  padding-right: 1.75rem;
}

.px-8 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.py-10 {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

.py-12 {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.py-20 {
  padding-top: 5rem;
  padding-bottom: 5rem;
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.py-8 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.pb-1 {
  padding-bottom: .25rem;
}

.pb-1\.5 {
  padding-bottom: .375rem;
}

.pb-10 {
  padding-bottom: 2.5rem;
}

.pb-12 {
  padding-bottom: 3rem;
}

.pt-1 {
  padding-top: .25rem;
}

.pt-20 {
  padding-top: 5rem;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.font-fancy {
  font-family: great-vibes, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
}

.font-sans {
  font-family: quicksand, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.text-4xl {
  font-size: 2.25rem;
  line-height: 2.5rem;
}

.text-5xl {
  font-size: 3rem;
  line-height: 1;
}

.text-6xl {
  font-size: 3.75rem;
  line-height: 1;
}

.text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.font-bold {
  font-weight: 700;
}

.font-medium {
  font-weight: 500;
}

.font-normal {
  font-weight: 400;
}

.font-semibold {
  font-weight: 600;
}

.uppercase {
  text-transform: uppercase;
}

.leading-10 {
  line-height: 2.5rem;
}

.leading-none {
  line-height: 1;
}

.leading-snug {
  line-height: 1.375;
}

.text-\[\#f3f4fe\] {
  --tw-text-opacity: 1;
  color: rgb(243 244 254 / var(--tw-text-opacity));
}

.text-body-color {
  --tw-text-opacity: 1;
  color: rgb(99 115 129 / var(--tw-text-opacity));
}

.text-dark {
  --tw-text-opacity: 1;
  color: rgb(9 14 52 / var(--tw-text-opacity));
}

.text-lightgreen {
  --tw-text-opacity: 1;
  color: rgb(192 216 211 / var(--tw-text-opacity));
}

.text-primary {
  --tw-text-opacity: 1;
  color: rgb(2 100 80 / var(--tw-text-opacity));
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.underline {
  text-decoration-line: underline;
}

.opacity-10 {
  opacity: .1;
}

.shadow-md {
  --tw-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -2px #0000001a;
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.transition {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.duration-300 {
  transition-duration: .3s;
}

.ease-in-out {
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.before\:mx-auto:before {
  content: var(--tw-content);
  margin-left: auto;
  margin-right: auto;
}

.before\:my-5:before {
  content: var(--tw-content);
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
}

.before\:block:before {
  content: var(--tw-content);
  display: block;
}

.before\:h-1:before {
  content: var(--tw-content);
  height: .25rem;
}

.before\:w-\[40px\]:before {
  content: var(--tw-content);
  width: 40px;
}

.before\:rounded-full:before {
  content: var(--tw-content);
  border-radius: 9999px;
}

.before\:bg-white:before {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.after\:my-7:after {
  content: var(--tw-content);
  margin-top: 1.75rem;
  margin-bottom: 1.75rem;
}

.after\:block:after {
  content: var(--tw-content);
  display: block;
}

.after\:h-0:after {
  content: var(--tw-content);
  height: 0;
}

.after\:h-0\.5:after {
  content: var(--tw-content);
  height: .125rem;
}

.after\:w-\[40\%\]:after {
  content: var(--tw-content);
  width: 40%;
}

.after\:bg-lightgreen:after {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: rgb(192 216 211 / var(--tw-bg-opacity));
}

.hover\:bg-dark:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(9 14 52 / var(--tw-bg-opacity));
}

.hover\:bg-white:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.hover\:text-primary:hover {
  --tw-text-opacity: 1;
  color: rgb(2 100 80 / var(--tw-text-opacity));
}

.hover\:underline:hover {
  text-decoration-line: underline;
}

.hover\:shadow-lg:hover {
  --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

@media (min-width: 640px) {
  .sm\:px-10 {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }

  .sm\:px-12 {
    padding-left: 3rem;
    padding-right: 3rem;
  }

  .sm\:px-3 {
    padding-left: .75rem;
    padding-right: .75rem;
  }

  .sm\:text-4xl {
    font-size: 2.25rem;
    line-height: 2.5rem;
  }

  .sm\:text-7xl {
    font-size: 4.5rem;
    line-height: 1;
  }

  .sm\:leading-snug {
    line-height: 1.375;
  }

  .sm\:before\:my-10:before {
    content: var(--tw-content);
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
  }
}

@media (min-width: 768px) {
  .md\:mb-20 {
    margin-bottom: 5rem;
  }

  .md\:w-1\/2 {
    width: 50%;
  }

  .md\:bg-glasses {
    background-image: url("glass1.7a529bea.svg"), url("glass2.456e937d.svg");
  }

  .md\:bg-\[position\:bottom_35\%_left_80px\,_bottom_15\%_right_80px\] {
    background-position: left 80px bottom 35%, right 80px bottom 15%;
  }

  .md\:bg-no-repeat {
    background-repeat: no-repeat;
  }

  .md\:p-16 {
    padding: 4rem;
  }

  .md\:p-\[70px\] {
    padding: 70px;
  }

  .md\:text-2xl {
    font-size: 1.5rem;
    line-height: 2rem;
  }

  .md\:text-\[158px\] {
    font-size: 158px;
  }

  .md\:text-\[68px\] {
    font-size: 68px;
  }

  .md\:text-\[98px\] {
    font-size: 98px;
  }

  .md\:text-base {
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .md\:leading-\[98px\] {
    line-height: 98px;
  }

  .md\:leading-snug {
    line-height: 1.375;
  }

  .md\:after\:my-14:after {
    content: var(--tw-content);
    margin-top: 3.5rem;
    margin-bottom: 3.5rem;
  }
}

@media (min-width: 1024px) {
  .lg\:-right-10 {
    right: -2.5rem;
  }

  .lg\:mb-14 {
    margin-bottom: 3.5rem;
  }

  .lg\:mb-24 {
    margin-bottom: 6rem;
  }

  .lg\:mb-8 {
    margin-bottom: 2rem;
  }

  .lg\:mt-28 {
    margin-top: 7rem;
  }

  .lg\:mt-40 {
    margin-top: 10rem;
  }

  .lg\:mt-\[60px\] {
    margin-top: 60px;
  }

  .lg\:block {
    display: block;
  }

  .lg\:flex {
    display: flex;
  }

  .lg\:hidden {
    display: none;
  }

  .lg\:w-1\/2 {
    width: 50%;
  }

  .lg\:w-1\/3 {
    width: 33.3333%;
  }

  .lg\:w-2\/3 {
    width: 66.6667%;
  }

  .lg\:w-5\/12 {
    width: 41.6667%;
  }

  .lg\:border-8 {
    border-width: 8px;
  }

  .lg\:bg-\[size\:auto\] {
    background-size: auto;
  }

  .lg\:px-16 {
    padding-left: 4rem;
    padding-right: 4rem;
  }

  .lg\:py-20 {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }

  .lg\:py-40 {
    padding-top: 10rem;
    padding-bottom: 10rem;
  }

  .lg\:py-9 {
    padding-top: 2.25rem;
    padding-bottom: 2.25rem;
  }

  .lg\:pb-40 {
    padding-bottom: 10rem;
  }

  .lg\:pb-\[90px\] {
    padding-bottom: 90px;
  }

  .lg\:pt-\[120px\] {
    padding-top: 120px;
  }

  .lg\:text-4xl {
    font-size: 2.25rem;
    line-height: 2.5rem;
  }

  .lg\:opacity-100 {
    opacity: 1;
  }

  .lg\:before\:my-14:before {
    content: var(--tw-content);
    margin-top: 3.5rem;
    margin-bottom: 3.5rem;
  }
}

@media (min-width: 1280px) {
  .xl\:w-5\/12 {
    width: 41.6667%;
  }

  .xl\:p-\[60px\] {
    padding: 60px;
  }
}

@media (min-width: 1536px) {
  .\32 xl\:text-\[110px\] {
    font-size: 110px;
  }
}

/*# sourceMappingURL=index.ac480bc9.css.map */
